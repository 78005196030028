<template>
    <div class="container" id="ScoreRegistration">
        <el-page-header @back="$router.back()" :content="subLeagueName + '-按报名表录入成绩'"></el-page-header>
        <div class="ScoreRegistration_content">
            <el-row v-if="groups.length > 0">
                <el-form>
                    <el-form-item label="组别：" style="margin-bottom: 0px !important;">
                        <el-button v-for="(item,index) in groups" :key="index"
                            size="small"
                            :plain="item.GroupId == groupId ? false : true"
                            type="primary"
                            @click="groupId = item.GroupId"
                        >{{item.GroupName}}</el-button>
                    </el-form-item>
                </el-form>
                <p class="hint" v-if="isGet && !loading && list.length == 0">提示：该组别没有参赛者，你可能需要先<el-button type="text" v-href="`/Match/ImportEnroll/${subLeagueId}?name=${subLeagueName}`">上传报名册</el-button>。</p>
                <p class="hint" style="color: #F56C6C" v-else-if="isGet && !loading && isResult">注意：该组别已有比赛成绩数据，重新录入名次将覆盖已有数据。</p>
            </el-row>
            <el-row v-else>
                数据加载中...
            </el-row>
            <div class="statistics" v-if="isGet && !loading && list.length > 0">
                <p style="width:240px" v-if="cardsMastersInfo.MastersPayStatus > 0">
                    <el-form>
                        <el-form-item label="最终参赛者数量：" style="margin-bottom: 0px !important;">
                            <el-input type="number" v-model.number="total" placeholder="请输入" size="small" style="width:100px"></el-input>
                        </el-form-item>
                    </el-form>
                </p>
                <p v-else>共{{list.length}}个参赛者</p>
                <div v-show="list.length > 1">
                    <el-button 
                        type="primary" size="small"
                        @click="reorder()"
                    >按录入的名次重新排序</el-button>
                </div>
            </div>
            <el-row>
                <el-table
                    v-loading="loading"
                    element-loading-text="数据加载中"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    :data="list"
                    border
                    size="mini"
                >   
                    <el-table-column label="序号" width="50" type="index" align="center"></el-table-column>
                    <el-table-column prop="Names" label="参赛者"></el-table-column>
                    <el-table-column prop="DeputationName" label="代表队"></el-table-column>
                    <el-table-column prop="CompanyName" label="代表机构"></el-table-column>
                    <el-table-column prop="ComanyMemberNo" label="机构会员号" max-width="120"></el-table-column>
                    <el-table-column prop="CertificateNo" label="证件号码" max-width="120"></el-table-column>
                    <el-table-column prop="Phone" label="手机号码" max-width="120"></el-table-column>
                    <el-table-column prop="MemberNo" label="个人会员号" max-width="120"></el-table-column>
                    <el-table-column label="名次" width="80">
                        <template slot-scope="scope">
                            <el-input v-model.number="rankings[scope.row.TeamId]" type="number" style="width:100%" size="small" :key="scope.row.TeamId"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            <el-row style="margin:30px 0 20px" v-if="isGet && !loading && list.length > 0">
                <el-button type="success" style="width:100px" @click="saveRanking">保存</el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import {
    GetLeagueEventsGroup,
    GetEnrollTeamsForScoreOrderInput,
    SaveInputScoreOrder
} from '@/api'
import { mapGetters } from 'vuex';
export default {
    data(){
        var id = parseInt(this.$route.params.subLeagueId);
        return {
            subLeagueId: isNaN(id) ? 0 : id,
            subLeagueName: this.$route.query.name,
            groups: [],
            groupId: 0,
            eventsId: 0,
            total: null,
            list: [],
            loading: false,
            isGet: false,
            rankings: {}
        }
    },
    created(){
        this.getGroups();
    },
    watch:{
        groupId(groupId){
            var id = 0,total = null;
            if(this.groups && this.groups.length > 0){
                let groupObj = this.groups.filter(item => groupId == item.GroupId)[0] || {};
                id = groupObj.EventsId || 0;
                total = groupObj.TeamCount || null;
            }
            this.eventsId = id;
            this.total = total;
            this.rankings = {};
            this.list = [];
            this.getList();
        },
        list:{
            deep: true,
            immediate: true,
            handler(arr){
                if(arr.length > 0){
                    let rankingObj = JSON.parse(JSON.stringify(this.rankings));
                    for(var i=0;i<arr.length;i++){
                        let obj = arr[i];
                        if(rankingObj[obj.TeamId] == undefined){
                            rankingObj[obj.TeamId] = obj.Ranking;
                        }
                    }
                    this.$set(this,'rankings',rankingObj);
                }
            }
        }
    },
    computed:{
        ...mapGetters(['cardsMastersInfo'])
    },
    methods:{
        getGroups(){
            GetLeagueEventsGroup({
                SubLeagueId: this.subLeagueId
            }).then(data=>{
                if(data.Code == 0){
                    let groupList = data.GroupList || [];
                    if(this.groupId == 0 && groupList.length > 0){
                        this.groupId = groupList[0].GroupId;
                    }
                    this.groups = groupList;
                }
            })
        },
        getList(){
            this.loading = true;
            GetEnrollTeamsForScoreOrderInput({
                SubLeagueId: this.subLeagueId,
                GroupId: this.groupId
            }).then((data)=>{
                this.loading = false;
                if(data.Code == 0){
                    if(!this.isGet) this.isGet = true;
                    this.isResult = data.IsHaveScoreOrder;
                    let teamList = data.TeamList || [];
                    this.list = teamList;
                    this.total ??= teamList.length;
                }
            }).catch(() => {
                this.loading = false;
            })
        },
        //保存名次
        saveRanking(){
            var rankings = this.rankings,
                rankList = [];
            for(var key in rankings){
                let value = rankings[key],
                    valueNum = parseInt(rankings[key]);
                if(value && !isNaN(valueNum) && valueNum > 0){
                    rankList.push({
                        TeamId: parseInt(key),
                        Ranking: valueNum
                    })
                }
            }
            if(rankList.length == 0){
                this.$message.error("请输入名次");
                return;
            }
            if(this.cardsMastersInfo.MastersPayStatus > 0){
                let min = rankList.length,
                max = this.list.length;
                if(this.total != 0 && !this.total){
                    this.$message.error(`请输入最终参赛者数量`);
                    return;
                }else if(this.total < min){
                    this.$message.error(`最终参赛者数量不能小于已有名次的人数（ ${min} ）`);
                    return;
                }else if(this.total > max){
                    this.$message.error(`最终参赛者数量不能大于已报名的人数（ ${max} ）`);
                    return;
                }
            }
            const loading = this.$loading({
                lock: true,
                text: '正在保存成绩...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            SaveInputScoreOrder({
                "SubLeagueId": this.subLeagueId,
                "GroupId": this.groupId,
                "RankList": rankList,
                "TeamCount": this.total
            }).then(data=>{
                loading.close();
                if(data.Code == 0){
                    this.$message.success('保存成功');
                    this.getGroups();
                    this.getList();
                }
            }).catch(err=>{
                loading.close();
            })
        },
        reorder(){
            var list = JSON.parse(JSON.stringify(this.list)),
                rankings = this.rankings;
            list.sort(function(back, front){
                if(!rankings[front.TeamId] && rankings[back.TeamId]){
                    return -1;
                }else if(!rankings[back.TeamId] && rankings[front.TeamId]){
                    return  1;
                }else if(!rankings[back.TeamId] && !rankings[front.TeamId]){
                    return 0;
                }else{
                    return rankings[back.TeamId] - rankings[front.TeamId];
                }
            })
            this.$set(this, 'list', list);
        },
        
    }
}
</script>
<style scoped>
.ScoreRegistration_content .hint {
    font-size: 14px;
}
</style>